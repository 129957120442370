import { render, staticRenderFns } from "./ideaList.vue?vue&type=template&id=699c8782&scoped=true&"
import script from "./ideaList.vue?vue&type=script&lang=js&"
export * from "./ideaList.vue?vue&type=script&lang=js&"
import style0 from "./ideaList.vue?vue&type=style&index=0&id=699c8782&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699c8782",
  null
  
)

export default component.exports