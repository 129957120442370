<template>
	<div>
		<el-upload class="avatar-uploader" style="display: none" action="#" :http-request="handleUpload"
			>
			<el-button type="primary" plain>上传</el-button>
		</el-upload>
		<quill-editor ref="myQuillEditor" v-model="content" :options="editorOption" @change="onEditorChange($event)" />
	</div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { put } from '@/api/upload.js'
export default {
	components: { quillEditor },
	data() {
		return {
			fileList: [],
			content: '',
			editorOption: {
				theme: "snow",
				placeholder: "请输入正文",
				modules: {
					// ImageExtend: {
					// 	loading: true,
					// 	action: 'http://yangningbo.com',
					// 	response: res => {
					// 		console.log('这是返回的图片', res);
					// 	},
					// },
					// imageDrop: true,
					// imageResize: {
					// 	displayStyles: {
					// 		backgroundColor: "black",
					// 		border: "none",
					// 		color: "white"
					// 	},
					// 	modules: ["Resize", "DisplaySize", "Toolbar"]
					// },
					toolbar: {
						container: [
							['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
							['blockquote', 'code-block'],     //引用，代码块
							[{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
							[{ 'list': 'ordered' }, { 'list': 'bullet' }],     //列表
							[{ 'script': 'sub' }, { 'script': 'super' }],   // 上下标
							[{ 'indent': '-1' }, { 'indent': '+1' }],     // 缩进
							[{ 'direction': 'rtl' }],             // 文本方向
							// [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
							// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
							// [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
							// [{ 'font': [] }],     //字体
							// [{ 'align': [] }],    //对齐方式
							// ['clean'],    //清除字体样式
							['image', 'video'],    //上传图片、上传视频
						],
						handlers: {
							image: function (value) {
								if (value) {
									document.querySelector(".avatar-uploader input").click();
								} else {
									this.quill.format("image", false);
								}
							},
						}
					},
					// toolbar: [
					// 	['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
					// 	['blockquote', 'code-block'],     //引用，代码块
					// 	[{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
					// 	[{ 'list': 'ordered' }, { 'list': 'bullet' }],     //列表
					// 	[{ 'script': 'sub' }, { 'script': 'super' }],   // 上下标
					// 	[{ 'indent': '-1' }, { 'indent': '+1' }],     // 缩进
					// 	[{ 'direction': 'rtl' }],             // 文本方向
					// 	// [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
					// 	// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
					// 	// [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
					// 	// [{ 'font': [] }],     //字体
					// 	// [{ 'align': [] }],    //对齐方式
					// 	// ['clean'],    //清除字体样式
					// 	['image', 'video'],    //上传图片、上传视频
					// ],
				},
			},
		}
	},
	computed: {

	},
	mounted() {
	},
	methods: {
		getFileName(name) {
			return name.substring(name.lastIndexOf("."));//.txt
		},
		onEditorChange() {
			this.$emit("submit", this.content)
		},
		// // 上传前
		// beforeUpload(file) {
		// 	console.log(file);
		// 	// 限制上传类型      
		// 	// const fileExtensions = this.getFileName(file.name) === '.png' || this.getFileName(file.name) === '.jpg' || this.getFileName(file.name) === '.webp'
		// 	// // //限制的上限为20M      
		// 	// // const max20M = file.size / 1024 / 1024 < 20;
		// 	// if (!fileExtensions) {
		// 	// 	this.$message.error('上传文件类型只能是 .png, .jpg, .webp 格式!');
		// 	// }
		// 	// if (!max20M) {
		// 	// 	this.$message.error('上传文件大小不能超过 20MB!');
		// 	// }
		// 	// console.log(fileExtensions, "fileExtensionsfileExtensions");
		// 	return fileExtensions;
		// },
		async handleUpload(option) {
			// 获取文件的后缀名
			// let objName = getFileNameUUID()
			var obj = option.file.name
			var index = obj.lastIndexOf(".");
			obj = obj.substring(index, obj.length);// 这里拿到的是文件的后缀

			// 生成的文件名，保留文件后缀名，进行拼接      
			// let objName = getFileNameUUID() + this.getFileName(option.file.name)
			// let objName = this.getFileName(option.file.name)

			const result = await put(option.file.name, option.file)
			if (result.res.status == 200) {
				this.editorSelection(result.url)
			} else {
				this.$message.error('上传失败')
			}
			// 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
			// put(`flieName/${objName}`, option.file).then(res => {
			// 	console.log(res, 'res')
			// 	// 上传成功之后，转换真实的地址
			// 	signatureUrl(`flieName/${objName}`).then(res => {
			// 		console.log(res)
			// 	})
			// })
		},
		editorSelection(imageUrl) {
			let quill = this.$refs.myQuillEditor.quill;
			// 获取光标所在位置
			let length = quill.getSelection().index;
			// 插入图片，imageUrl为服务器返回的图片链接地址
			quill.insertEmbed(length, "image", imageUrl);
			// 调整光标到最后
			quill.setSelection(length + 1);
		},

	}
};
</script>
<style lang="scss" scoped>
.quill-editor {
	line-height: normal !important;
	height: 280px;
}
</style>
