<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">意见反馈</div>
				<img class="HSDialogCloseImg" src="../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<!-- <el-row>
					<el-col :span="10">
						<el-form-item label="联系姓名" >
							<el-input v-model="name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="10">
						<el-form-item label="联系电话" >
							<el-input v-model="phone"></el-input>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row>
					<el-col :span="10">
						<el-form-item label="反馈标题" required>
							<el-input v-model="title"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="反馈内容" required>
							<div>
								<editor @submit="submit_editor"></editor>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	import editor from "../../../components/HS/editor.vue"
	export default {
		components:{editor},
		data() {
			return {
				name:'',
				phone:'',
				title:'',
				feedBackContent:'',
			}
		},
		computed:{
			
		},
		props:{
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			submit_editor(str){
				this.feedBackContent = str
			},
			// 提交
			submit(){
				if(this.title===''){
					this.$message({
						message: '请输入反馈标题',
						type: 'error'
					})
					return 
				}
				if(this.feedBackContent===''){
					this.$message({
						message: '请输入反馈内容',
						type: 'error'
					})
					return 
				}
				let params = {
					"title": this.title,
					"feedBackContent": this.feedBackContent,
				}
				let loading = this.$loading()
				this.$http.post("/business/FeedBack/Insert",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$emit("submit",{type:'success',data:''})
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialog{
		width:1200px;height: 700px;
	}
</style>
