<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">反馈详情</div>
				<img class="HSDialogCloseImg" src="../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="width:1200px;height: 600px;padding: 20px 30px;overflow: auto;">
				<div v-html="str"></div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 20px;"></div>
			<!-- <div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div> -->
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
			}
		},
		computed:{
			
		},
		props:{
			str:{
				default:''
			}
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
