<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewL">
				<div class="search">
					<el-input v-model="keyword" @keyup.enter.native="toSearch" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;">
					</el-input>
					<el-button type="primary" style="margin-right: 22px;" @click="toSearch">查询</el-button>
				</div>
				<!-- <div class="search">
					<div class="searchTitle">订单状态</div>
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in stateList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">结算方式</div>
					<el-select v-model="orderPayType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in paymentList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div> -->
				<!-- <div class="search">
					<div class="searchTitle">使用时间</div>
					<el-select v-model="applyType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in [{label:'全部',value:''},{label:'猫',value:'1'},{label:'犬',value:'2'}]" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div> -->
			</div>
			<div class="tabViewR">
				<el-button @click="reset">重置</el-button>
				<el-button type="primary" @click="dialogIdeaAdd_state=true" v-if="$buttonAuthority('feedback-ideaList-add')">新增意见反馈</el-button>
			</div>
		</div>
		<!-- 内容信息 -->
		<div class="view">
			<el-table :data="tableData" height="100%">
				<el-table-column align="center" min-width="120" label="反馈日期">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.createTime)}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="反馈人">
					<template slot-scope="scope">
						{{scope.row.createUserName}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="反馈标题">
					<template slot-scope="scope">
						{{scope.row.title}}
					</template>
				</el-table-column>
				<el-table-column min-width="80" label="反馈状态">
					<template slot-scope="scope">
						<span v-if="scope.row.state===1">待处理</span>
						<span v-if="scope.row.state===2">已处理</span>
						<span v-if="scope.row.state===3">不予处理</span>
					</template>
				</el-table-column>
				<el-table-column min-width="120" label="处理时间">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.handleTime)}}
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="处理说明">
					<template slot-scope="scope">
						{{scope.row.remark}}
					</template>
				</el-table-column>
				<el-table-column min-width="130" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="curItem=scope.row;dialogIdeaSee_state=true;">反馈内容</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="dialogIdeaAdd_state">
			<dialogIdeaAdd @submit="submit_dialogIdeaAdd"></dialogIdeaAdd>
		</div>
		<div v-if="dialogIdeaSee_state">
			<dialogIdeaSee :str="curItem.feedBackContent" @submit="submit_dialogIdeaSee"></dialogIdeaSee>
		</div>
	</div>
</template>
<script>
	import page from "../../../components/page.vue"
	import dialogIdeaAdd from "./dialogIdeaAdd.vue"
	import dialogIdeaSee from "./dialogIdeaSee.vue"
	export default {
		components: {page,dialogIdeaAdd,dialogIdeaSee},
		data() {
			return {
				// 搜索
				keyword: '', 
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 添加反馈
				dialogIdeaAdd_state:false,
				// 添查看反馈
				curItem:{},
				dialogIdeaSee_state:false,
			}
		},
		computed:{
		},
		mounted() {
			this.getTableData();
		},
		methods: {
			// 执行搜索
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			reset(){
				this.keyword = ''
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					state:0, // 0全部 1待处理；2接收；3不接受
					beginTime:'', 
					endTime:'',
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/business/FeedBack/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// del(row){
			// 	this.$confirm('确定要删除此订单吗?', '提示', {
			// 		confirmButtonText: '确定',
			// 		cancelButtonText: '取消',
			// 		type: 'warning'
			// 	}).then(() => {
			// 		let loading = this.$loading();
			// 		this.$http.get('/order/OrderConsume/DeleteOrder', {OrderNo:row.orderNo}).then(res => {
			// 			loading.close();
			// 			if (res.code == 0) {
			// 				this.$message({
			// 					type: 'success',
			// 					message: '删除成功!'
			// 				});
			// 				this.getTableData();
			// 			}
			// 		})
			// 	})
			// },
			// 添加反馈
			submit_dialogIdeaAdd(obj){
				this.dialogIdeaAdd_state = false;
				if(obj.type==='success'){
					this.$message({
						type: 'success',
						message: '操作成功!'
					});
					this.getTableData()
				}
			},
			// 查看反馈
			submit_dialogIdeaSee(){
				this.dialogIdeaSee_state = false;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;
		color: $fontColor;
		// 搜索信息
		.tabView {
			height: 80px; border-radius: 20px;background: #fff;margin-bottom: 16px;display: flex;justify-content: space-between;width:100%;padding:0 20px;box-sizing: border-box;
			.tabViewL{
				.search {
					margin-right: 12px;display: inline-flex;padding:20px 0;
					.searchTitle {
						font-size: 14px;width:60px;color: $fontColor;line-height: 40px;
					}
				}
			}
			.tabViewR {
				padding-top:20px;
			}
		}
		// 内容信息
		.view {
			position: relative;height: calc(100% - 96px);padding-bottom:52px;box-sizing: border-box;width:100%;border-radius: 20px;overflow: hidden;
		}
	}
</style>
